// Imports
// ------------
import React, { useRef, useState } from "react";
import Icon from '@icons';
import SingleMember from './SingleMember';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow } from "swiper";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

// Styles
// ------------
import 'swiper/css';
import "swiper/css/effect-coverflow";
import { Jacket, Instructions } from './styles';

// Component
// ------------
const MobileMembers = ({ members }) => {
    // NOTE • Breakpoints
    const bp = useBreakpoint();

    return (
        <Jacket padBottom>
            <Instructions>
                <Icon type="swipe" />
            </Instructions>

            <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={bp.medium ? 1.5 : 1.2}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false,
                }}
                modules={[EffectCoverflow]}
                className="mySwiper"
            >
                {members.map(({ firstName, lastName, role, hasLinkedin, linkedinUrl, profilePhoto, about }) => (
                    <SwiperSlide key={firstName + lastName}>
                        <SingleMember
                            key={firstName + lastName}
                            firstName={firstName}
                            lastName={lastName}
                            role={role}
                            hasLinkedin={hasLinkedin}
                            linkedinUrl={linkedinUrl}
                            profilePhoto={profilePhoto}
                            about={about}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </Jacket>
    );
}

export default MobileMembers;