// Imports
// ------------
import React, { useRef, useEffect, useState } from 'react';
import Profile from '../../Profile';
import ProfilePortal from '../../ProfilePortal';
import { GatsbyImage } from 'gatsby-plugin-image';
import gsap from 'gsap';
import { action } from 'mobx';
import { profileBg } from '@states';
import Body from '@utils/bodylock';
import { AnimatePresence } from 'framer-motion';

// Styles
// ------------
import { Jacket, Name, Role, Floato } from './styles';

// Component
// ------------
const SingleMember = ({ role, profilePhoto, firstName, lastName, hasLinkedin, linkedinUrl, about }) => {
    // NOTE • Image Destructure
    const { alt, bg, thumb } = profilePhoto;

    // NOTE • States
    const [profileOpen, setProfileOpen] = useState(false);

    // NOTE • Refs
    const cursor = useRef();
    const trigger = useRef();

    // NOTE • Cursor Followers

    useEffect(() => {
        function moveCircle(e) {
            gsap.to(cursor.current, 0.3, {
                css: {
                    zIndex: 3,
                    opacity: 1,
                    left: e.clientX,
                    top: e.clientY,
                }
            });
        }

        // Container
        // ------
        trigger.current.addEventListener('mouseover', () => {
            gsap.to(cursor.current, 0.4, { scale: 1, autoAlpha: 1, })
            trigger.current.addEventListener('mousemove', moveCircle);
        });

        trigger.current.addEventListener('mouseout', () => {
            gsap.to(cursor.current, 0.4, { scale: 0.1, autoAlpha: 0, })
        });
    }, []);

    const openProfile = () => {
        setProfileOpen(true);

        action(() => {
            profileBg.isActive = false;
        });


        setTimeout(() => {
            Body.lock();
        }, 750);
    }

    const closeProfile = () => {
        setProfileOpen(false);

        action(() => {
            profileBg.isActive = true;
        });

        setTimeout(() => {
            Body.unlock();
        }, 750);
    }

    const altText = alt ? alt : `Sirio Strategies • Team Member ${firstName + " " + lastName}`;

    return (
        <>
            {/* ANCHOR • Main Component */}
            <Jacket ref={trigger} onClick={openProfile}>
                <Floato ref={cursor}>
                    <GatsbyImage image={thumb} alt={altText} />
                </Floato>

                <Name>{firstName + " " + lastName}</Name>
                <Role>{role}</Role>
            </Jacket>

            {/* ANCHOR • Portal To: Profile Modal */}
            <ProfilePortal>
                <AnimatePresence mode="wait" initial={false}>
                    {profileOpen && (
                        <Profile
                            onClick={closeProfile}
                            firstName={firstName}
                            lastName={lastName}
                            role={role}
                            pic={bg}
                            hasLinkedin={hasLinkedin}
                            linkedinUrl={linkedinUrl}
                            content={about}
                            altText={altText}
                        />
                    )}
                </AnimatePresence>
            </ProfilePortal>
        </>
    );
}

export default React.memo(SingleMember);