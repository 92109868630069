// Imports
// ------------
import React, { useEffect, useRef } from 'react';
import { Row, Column } from '@waffl';
import Icon from '@icons';
import SingleCase from './SingleCase';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Styles
// ------------
import { Jacket, Grad, Content, Heading, HelperText, Cases } from './styles';


// Component
// ------------
gsap.registerPlugin(ScrollTrigger);

const CaseStudies = ({ heading, cases }) => {
    // NOTE • Breakpoints
    const bp = useBreakpoint();

    // NOTE • Refs
    const trigger = useRef();
    const target = useRef();

    // NOTE • Onload: scroll Animation
    useEffect(() => {
        const timer = setTimeout(() => {
            ScrollTrigger.matchMedia({
                '(min-width: 1024px)': () => {
                    gsap.to(target.current, {
                        opacity: 1,
                        ease: 'none',
                        scrollTrigger: {
                            trigger: trigger.current,
                            start: '0vh 50%',
                            end: '100vh 50%',
                            scrub: 0.5,
                            markers: false,
                        },
                    });
                },
                '(max-width: 1023px)': () => {
                    gsap.to(target.current, {
                        opacity: 1,
                        ease: 'none',
                        scrollTrigger: {
                            trigger: trigger.current,
                            start: '0% 50%',
                            end: '50% 50%',
                            scrub: 0.5,
                            markers: false,
                        },
                    });
                }
            });
        }, 250);

        return () => {
            clearTimeout(timer);
        }
    }, []);


    return (
        <>
            <Jacket pad ref={trigger}>
                <Grad ref={target} />

                <Row isExpanded isEqual>
                    {/* Text */}
                    <Column small={12} medium={10} pushMedium={1} large={5} pushLarge={1} mpad>
                        <Content>
                            <Heading>{heading}</Heading>
                            {bp.large ? (<HelperText>Choose a case study to discover more <Icon type="arrow-up-right" /></HelperText>) : null}
                        </Content>
                    </Column>

                    {/* Cards */}
                    <Column small={12} large={4} pushLarge={2} mpad isMaxHeight>
                        <Cases>
                            {cases.nodes.map((node, i) => (
                                <SingleCase
                                    key={i}
                                    details={node}
                                />
                            ))}
                        </Cases>
                    </Column>
                </Row>
            </Jacket>

            <div id="cases-portal" />
        </>
    );
}

export default CaseStudies;