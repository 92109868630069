// Imports
// ------------
import styled, { css, keyframes } from 'styled-components';
import { breakup, Div, P, ContentBlock } from '@tackl';
import { type } from '@tackl-type';
import { motion } from 'framer-motion';

// Exports
// ------------
// Animations
const marquee = keyframes`
    0% { transform: translateX(0); }
    100% { transform: translateX(-100%); }
`;


export const Jacket = styled(motion.section)(props => css`
    position: fixed;
    z-index: 999;
    top: 0; left: 0; right: 0; bottom: 0;
    background: rgba(000,000,000,0.9);
    opacity: 0;
`);


// NOTE • Picture
export const Picture = styled(motion.picture)(props => css`
    position: fixed;
    z-index: 1;
    top: 0; right: 0; bottom: 40%;
    width: 100%;
    overflow: hidden;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);

    ${breakup.large`
        width: 50%;
        bottom: 0;
    `}

    .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
    }
`);


export const Marquee = styled.div(props => css`
    display: none;

    ${breakup.large`
        display: block;
        position: absolute;
        z-index: 2;
        bottom: 0; left: 0; right: 0;
        overflow: hidden;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        pointer-events: none;

        span {
            ${type}
            display: inline-block;
            font-size: 12.8rem;
            line-height: 1.2;

            will-change: transform;
            transform: translateX(0);
            white-space: nowrap;
            animation: ${marquee} 10s linear infinite;

            background: -webkit-linear-gradient(0deg,
                ${props.theme.colors.brand.bc2} 0%,
                ${props.theme.colors.brand.bc3} 50%,
                ${props.theme.colors.brand.bc2} 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    `}
`);


// NOTE Bio
export const Bio = styled(motion.div)(props => css`
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 50vh 0 0;

    transform: translateY(0vh);
    overflow: scroll;
    overflow-x: hidden;

    ${breakup.large`
        padding: 6rem 6rem 0;
    `}
`);

export const BioPre = styled(Div)(props => css`
    position: relative;
    top: 0rem;
    left: 0;
    width: 100%;
    padding: 3.6rem;
    
    background: linear-gradient(90deg,
        ${props.theme.colors.brand.bc2} 0%,
        ${props.theme.colors.brand.bc3} 100%
    );

    ${breakup.large`
        width: 50vw;
        padding: 8.4rem;
    `}
`);

export const Name = styled.h2(props => css`
    ${type}
    font-size: 3.6rem;
	line-height: 1.2;
    margin: 0 0 1.2rem 0;

    ${breakup.large`
        font-size: 6rem;
		letter-spacing: -0.2rem;
        margin: 1.2rem 0 2.4rem;
    `}
`);

export const Role = styled(P)(props => css`
    color: ${props.theme.colors.brand.bc5};
`);

export const BioPost = styled(Div)(props => css`
    position: relative;
    z-index: 3;
    top: 0;
    right: 0;
    left: 0;
`);

export const BioPostContent = styled(ContentBlock)(props => css`
    min-height: 100vh;
    width: 100%;
    padding: 3.6rem;
    background: ${props.theme.colors.brand.bc5};

    ${breakup.large`
        width: 50vw;
        padding: 8.4rem;
    `}


    * { 
        color: ${props.theme.colors.brand.bc1trans60} !important;
    }
`);

// NOTE Close Modal

export const Social = styled(motion.a)(props => css`
    position: absolute;
    z-index: 4;
    top: 0rem;
    right: 0rem;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.2rem;
    transition: ${props.theme.easing.ease};

    background: ${props.theme.colors.brand.bc1};

    ${breakup.large`
        position: fixed;
        top: 4.8rem;
        right: 14.4rem;
        left: auto;
        bottom: auto;
        transform: translateX(-2.4rem) translateY(-1.2rem);
        background: transparent;
    `}

    svg {
        width: 3.6rem;
        height: 3.6rem;
        fill: ${props.theme.colors.brand.bc5};

        ${breakup.large`
            width: 4.8rem;
            height: 4.8rem;
        `}
    }

    &:hover {
        ${breakup.large`
            opacity: 0.6;
        `}
    }

`);

export const Close = styled(motion.button)(props => css`
    position: absolute;
    z-index: 4;
    right: 0rem;
    bottom: 0rem;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0;
    margin: 0;
    width: 6rem;
    height: 6rem;
    cursor: pointer;

    background: ${props.theme.colors.brand.bc1};

    ${breakup.large`
        position: fixed;
        top: 6rem;
        right: 6rem;
        bottom: auto;
        width: auto;
        height: auto;
        background: transparent;
    `}

    svg {
        width: 3.6rem;
        height: 3.6rem;
        stroke: ${props.theme.colors.brand.bc5};
        stroke-width: 0.3rem;
        mix-blend-mode: difference;

        ${breakup.large`
            width: 4.8rem;
            height: 4.8rem;
        `}
    }
`);