// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Article, H6, Span } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Article)(props => css`
    text-align: center;
`);

export const Name = styled(H6)(props => css`
    position: relative;
    z-index: 4;
    color: ${props.theme.colors.brand.bc5};
    pointer-events: none;
    margin: 2.4rem 0 1.2rem;
`);

export const Role = styled(Span)(props => css`
    position: relative;
    z-index: 4;
    color: ${props.theme.colors.brand.bc5};
    pointer-events: none;
    opacity: 0.6;
`);

export const Pic = styled.picture(props => css`
    position: relative;
    z-index: 4;
    pointer-events: none;

    width: calc(100vw - 7.2rem);
    max-width: 32.4rem;
    height: calc(100vw - 7.2rem);
    max-height: 32.4rem;

    overflow: hidden;

    .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
    }
`);