// Imports
// ------------
import React from 'react';
import SingleDo from './SingleDo';


import strategy3d from '@images/strategy-3d.mp4';
import insights3d from '@images/insights.mp4';
import evaluate3d from '@images/evaluate.mp4';

// Styles
// ------------
import { Jacket } from './styles';

// Component
// ------------
const WhatWeDo = ({ strategy, insights, evaluate }) => (
    <Jacket>
        <SingleDo
            title={strategy.title}
            text={strategy.text}
            threeD={strategy3d}
        />

        <SingleDo
            alt
            title={insights.title}
            text={insights.text}
            threeD={insights3d}
        />

        <SingleDo
            title={evaluate.title}
            text={evaluate.text}
            threeD={evaluate3d}
        />
    </Jacket>
);

export default WhatWeDo;