// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section, Div } from '@tackl';
import { type } from '@tackl-type';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    position: fixed;
    z-index: 11;
    top: 0; left: 0; right: 0;
    ${props.theme.vh}
    background: #02010c;
    overflow: hidden;
    transform: translateY(100%);

    ${breakup.large`
        height: 100vh;
        bottom: auto;
    `}

    .row,
    .col {
        height: 100%;
    }

    &:after {
        content: "";
        position: absolute;
        z-index: 2;
        top: 0; left: 0; right: 0; bottom: 0;
        background: ${props.theme.colors.brand.bc3};
        mix-blend-mode: overlay;
    }
`);

export const FakeJacket = styled(Section)(props => css`
    width: 100%;
    ${props.theme.vh}

    ${breakup.large`
        height: 100vh;
    `}
`);

export const Content = styled.h2(props => css`
    ${type}

    position: relative;
    z-index: 3;
    
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;

    font-size: 3.6rem;
    line-height: 1.1;
    letter-spacing: -1px;

    ${breakup.medium`
        font-size: 6rem;
        line-height: 1.2;
        letter-spacing: -2px;
    `}
`);


export const Picture = styled.picture(props => css`
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;
    transform: scale(2);
    overflow: hidden;
    mix-blend-mode: luminosity;
`);