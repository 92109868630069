// Imports
// ------------
import React from 'react';
import Icon from '@icons';
import { GatsbyImage } from 'gatsby-plugin-image';
import { StructuredText } from 'react-datocms';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Styles
// ------------
import { Jacket, Picture, Marquee, Bio, BioPre, BioPost, Social, Name, Role, BioPostContent, Close } from './styles';

// Component
// ------------
const Profile = ({ firstName, lastName, role, pic, hasLinkedin, linkedinUrl, altText, content, onClick }) => {
    // NOTE • Breakpoints
    const bp = useBreakpoint();

    // NOTE • Framer motion variables
    const modalFade = {
        initial: {
            opacity: 0,
        },
        animate: {
            opacity: 1,
            transition: { duration: 0.75, delay: 0, ease: [0.8, 0, 0, 1] },
        },
        exit: {
            opacity: 0,
            transition: { duration: 0.5, delay: 0.5, ease: [0.8, 0, 0, 1] },
        }
    };

    const pictureSlide = {
        initial: {
            clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
        },
        animate: {
            clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
            transition: { duration: 1, delay: 0.5, ease: [0.8, 0, 0, 1] },
        },
        exit: {
            clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
            transition: { duration: 1, delay: 0, ease: [0.8, 0, 0, 1] },
        },
    }

    const contentSlide = {
        initial: {
            y: '100%'
        },
        animate: {
            y: '0vh',
            transition: { duration: 1, delay: 0.5, ease: [0.8, 0, 0, 1] },
        },
        exit: {
            y: '100%',
            transition: { duration: 1, delay: 0, ease: [0.8, 0, 0, 1] },
        },
    }

    const buttons = {
        initial: {
            x: '12rem',
        },
        animate: {
            x: '0rem',
            transition: { duration: 0.25, delay: 0, ease: [0.8, 0, 0, 1] },
        },
        exit: {
            x: '12rem',
            transition: { duration: 0.25, delay: 0, ease: [0.8, 0, 0, 1] },
        }
    }

    const buttonsDesktop = {
        initial: {
            y: '-12rem',
        },
        animate: {
            y: '0rem',
            transition: { duration: 0.25, delay: 0, ease: [0.8, 0, 0, 1] },
        },
        exit: {
            y: '-12rem',
            transition: { duration: 0.25, delay: 0, ease: [0.8, 0, 0, 1] },
        }
    }

    const buttonsArg = bp.large ? buttonsDesktop : buttons;

    return (
        <Jacket {...modalFade} key="modal">
            <Picture {...pictureSlide} key="pic">
                <GatsbyImage image={pic} alt={altText} />

                {bp.large ? (
                    <Marquee>
                        <span>{firstName + " " + lastName}&nbsp;</span>
                        <span>{firstName + " " + lastName}&nbsp;</span>
                    </Marquee>
                ) : null}
            </Picture>

            <Bio {...contentSlide} key="content">
                <BioPre>
                    <Name>{firstName + " " + lastName}</Name>
                    <Role>{role}</Role>
                </BioPre>

                <BioPost>
                    <BioPostContent>
                        <StructuredText data={content.content} />
                    </BioPostContent>
                </BioPost>
            </Bio>

            <Close type="button" onClick={onClick} {...buttonsArg}>
                <Icon type="close" />
            </Close>

            {hasLinkedin && (
                <Social href={linkedinUrl} target="_blank" rel="noreferrer noopener" {...buttonsArg}>
                    <Icon type="linkedin" />
                </Social>
            )}
        </Jacket>
    );
}

export default React.memo(Profile);