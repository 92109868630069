// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Article, H6, Span } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Article)(props => css`
    position: relative;
    z-index: 13;
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 4.8rem calc(8.333vw + 1.8rem);

    &:before,
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
    }

    /* Background */
    &:before {
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        background: linear-gradient(90deg,
            ${props.theme.colors.brand.bc2} 0%,
            ${props.theme.colors.brand.bc3} 100%);
        opacity: 0;
        transition: opacity 0.5s ${props.theme.easing.bezzy};
    }

    /* Border */
    &:after {
        z-index: 1;
        left: calc(8.333vw + 1.8rem);
        right: calc(8.333vw + 1.8rem);
        height: 1px;
        background: ${props.theme.colors.brand.bc5};
        opacity: 0.2;
    }
    
    &:hover {
        ${breakup.large`
            cursor: pointer;

            &:before {
                opacity: 1;
            }
        `}
    }

    &:last-child {
        &:after { display: none; }
    }
`);

export const Name = styled(H6)(props => css`
    position: relative;
    z-index: 4;
    color: ${props.theme.colors.brand.bc5};
    pointer-events: none;
`);

export const Role = styled(Span)(props => css`
    position: relative;
    z-index: 4;
    color: ${props.theme.colors.brand.bc5};
    pointer-events: none;
`);

export const Floato = styled.picture(props => css`
    position: fixed;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
    opacity: 0;

    pointer-events: none;

    width: 32.4rem;
    height: 32.4rem;
    overflow: hidden;

    .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
    }
`);

export const BackgroundPhoto = styled.picture(props => css`
    position: fixed;
    z-index: 12;
    top: 0; left: 0; right: 0; bottom: 0;
    overflow: hidden;
    background: ${props.theme.colors.brand.bc1};
    opacity: ${props.isActive ? 1 : 0};

    .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
        filter: grayscale(100%);
        opacity: 0.2;
    }
`);