// Imports
// ------------
import React, { useState } from 'react';
import Profile from '../../Profile';
import ProfilePortal from '../../ProfilePortal';
import { GatsbyImage } from 'gatsby-plugin-image';
import { action } from 'mobx';
import { profileBg } from '@states';
import Body from '@utils/bodylock';
import { AnimatePresence } from 'framer-motion';

// Styles
// ------------

import { Jacket, Name, Role, Pic } from './styles';

// Component
// ------------
const SingleMember = ({ firstName, lastName, role, profilePhoto, hasLinkedin, linkedinUrl, about }) => {
    // NOTE • Image Destructure
    const { bg, alt, thumb } = profilePhoto;

    const altText = alt ? alt : `Sirio Strategies • Team Member ${firstName + " " + lastName}`;

    // NOTE • States
    const [profileOpen, setProfileOpen] = useState(false);


    const openProfile = () => {
        setProfileOpen(true);

        action(() => {
            profileBg.isActive = false;
        });


        setTimeout(() => {
            Body.lock();
        }, 750);
    }

    const closeProfile = () => {
        setProfileOpen(false);

        action(() => {
            profileBg.isActive = true;
        });

        setTimeout(() => {
            Body.unlock();
        }, 750);
    }

    return (
        <>
            <Jacket onClick={openProfile}>
                <Pic>
                    <GatsbyImage image={thumb} alt={altText} />
                </Pic>
                <Name>{firstName + " " + lastName}</Name>
                <Role>{role}</Role>
            </Jacket>

            {/* ANCHOR • Portal To: Profile Modal */}
            <ProfilePortal>
                <AnimatePresence mode="wait" initial={false}>
                    {profileOpen && (
                        <Profile
                            onClick={closeProfile}
                            firstName={firstName}
                            lastName={lastName}
                            role={role}
                            pic={bg}
                            hasLinkedin={hasLinkedin}
                            linkedinUrl={linkedinUrl}
                            content={about}
                            altText={altText}
                        />
                    )}
                </AnimatePresence>
            </ProfilePortal>
        </>
    );
}

export default React.memo(SingleMember);