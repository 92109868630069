// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Article, P, Em } from '@tackl';

// Exports
// ------------
export const ProjectType = styled(Em)(props => css`
    position: absolute;
    z-index: 3;
    top: 3.6rem;
    left: 3.6rem;

    padding: 0.6rem 1.2rem;

    color: ${props.theme.colors.brand.bc5};
    border: 2px solid ${props.theme.colors.brand.bc5};
    border-radius: 4.8rem;


    ${breakup.large`
        padding: 1.2rem 2.4rem;
    `}

    span {
        display: block;
        transform: translateY(0.2rem);
    }
`);

export const ProjectTitle = styled(P)(props => css`
    position: absolute;
    z-index: 3;
    bottom: 3.6rem; left: 3.6rem; right: 3.6rem;
    color: ${props.theme.colors.brand.bc5};
`);

export const Picture = styled.picture(props => css`
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 1;

    &:after {
        content: "";
        position: absolute;
        z-index: 2;
        top: 0; left: 0; right: 0; bottom: 0;
        background: ${props.theme.colors.global.black};
        opacity: 0.4;
    }

    .gatsby-image-wrapper{
        width: 100%;
        height: 100%;
    }

    img {
        transition: all .75s ${props.theme.easing.bezzy} !important;
    }
`);

export const Jacket = styled(Article)(props => css`
    position: relative;
    height: 40rem;
    width: 31.8rem;
    background: ${props.theme.colors.brand.bc1};
    overflow: hidden;
    flex: none;

    ${breakup.medium`
        width: 100%;
        max-width: 44.4rem;
        height: 54rem;
    `}

    ${breakup.large`
        flex: none;
    `}

    &:hover {
        ${breakup.large`
            cursor: pointer;
        `}
    }

    ${breakup.large`
        &:hover {
            ${Picture} {
                img {
                    transform: scale(1.15) rotate(-6deg);
                }
            }
        }
    `}
`);