// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    position: relative;
    z-index: 13;
    background: ${props.theme.colors.brand.bc1};
`);


export const Instructions = styled.div(props => css`
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 2.4rem;

    ${breakup.medium`
        margin-bottom: ${props.theme.space.medium};
    `}

    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 50%; 
        transform: translateY(-50%);

        height: 1px;
        background: ${props.theme.colors.brand.bc5};
        opacity: 0.5;
        width: calc(50% - 2.4rem);
    }

    &:before {
        left: 0;
    }

    &:after {
        right: 0;
    }


    svg {
        fill: none;
        stroke: ${props.theme.colors.brand.bc5};
        opacity: 0.5;
    }
`);