// Imports
// ------------
import React from 'react';
import Hero from '@parts/Home/Hero';
import Introduction from '@parts/Home/Introduction';
import WhatWeDo from '@parts/Home/WhatWeDo';
import CaseStudies from '@parts/Home/CaseStudies';
import Team from '@parts/Home/Team';
import CallToAction from '@parts/CallToAction';
import Footer from '@parts/Footer';
import Seo from '@parts/Seo';
import { graphql } from 'gatsby';



// Component
// ------------
const IndexPage = ({ data }) => {
	const dato = data.page;

	return (
		<>
			<Seo
				title={data.seo.seoMeta.title}
				desc={data.seo.seoMeta.desc}
			/>

			<Hero
				pageTitle={dato.pageTitle}
				subtext={dato.subtext}
			/>

			<Introduction
				text={dato.ethosText}
			/>

			<WhatWeDo
				strategy={{
					title: dato.strategyTitle,
					text: dato.strategyText
				}}
				insights={{
					title: dato.insightsTitle,
					text: dato.insightsText
				}}
				evaluate={{
					title: dato.evaluateTitle,
					text: dato.evaluateText
				}}
			/>

			<CaseStudies
				heading={dato.caseStudiesTitle}
				cases={data.cases}
			/>

			<Team
				heroImage={dato.teamImage}
				heroTitle={dato.teamTitle}
				team={data.team.nodes}
			/>


			<CallToAction
				title={data.cta.title}
			/>

			<Footer />
		</>
	);
}

export default IndexPage;

// GraphQL
// ------------
export const query = graphql`
	query HomePageQuery {
		# NOTE • SEO
		seo: datoCmsHomePage {
			seoMeta {
				title
				desc: description
			}
		}

		# NOTE • Call To Action
		cta: datoCmsCallToAction {
			title
		}

		# NOTE • Cases
		cases: allDatoCmsCaseStudy {
			nodes {
				id
				title: caseStudyTitle
				projectType {
					name: filterName
				}
				featImage {
					alt
					data: gatsbyImageData(
						width: 720
						height: 900
						placeholder: BLURRED
						layout: FULL_WIDTH
					)
				}
				need:theNeed {
					value
				}
				solution {
					value
				}
				value: valueDelivered {
					value
				}
			}
		}

		team: allDatoCmsNewsAuthor {
			nodes {
				id
				firstName: name
				lastName
				role: authorJobTitle
				hasLinkedin
				linkedinUrl
				about {
					content: value
				}
				profilePhoto: authorPicture {
					alt
					bg: gatsbyImageData(
						width: 1440
						height: 900
						placeholder: BLURRED
						layout: FULL_WIDTH
					)
					thumb: gatsbyImageData(
						width: 324
						height: 324
						placeholder: BLURRED
						layout: FULL_WIDTH
					)
				}
			}
		}

		# NOTE • News
		news: allDatoCmsNewsPost {
			nodes {
				id
				slug
				title
				writtenBy {
					id
					name
					pic: authorPicture {
						alt
						data: gatsbyImageData(
							width: 72
							height: 72
							placeholder: BLURRED
							layout: FIXED
						)
					}
				}

				bg: featuredMedia {
					alt
					data: gatsbyImageData(
						width: 1440
						height: 900
						placeholder: BLURRED
						layout: FULL_WIDTH
					)
				}
			}
		}


		# NOTE • Cases
		page: datoCmsHomePage {

			# NOTE • Hero
			pageTitle
			subtext

			# NOTE • Intro
			ethosText

			# NOTE • What We Do
			strategyTitle 
			insightsTitle
			evaluateTitle
			strategyText {
				value
				blocks
				links
				__typename
			}
			insightsText {
				value
				blocks
				links
				__typename
			}
			evaluateText {
				value
				blocks
				links
				__typename
			}

			# NOTE • Case Studies
			caseStudiesTitle

			# NOTE • team
			teamTitle
			teamImage {
				alt
				data: gatsbyImageData(
					width: 1440
					height: 900
					placeholder: BLURRED
					layout: FULL_WIDTH
				)
			}
		}
	}
`
