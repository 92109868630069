// Imports
// ------------
import React, { useState } from 'react';
import FullCaseStudy from './FullCaseStudy';
import CasesPortal from '../CasesPortal';
import Body from '@utils/bodylock';
import { GatsbyImage } from 'gatsby-plugin-image';
import { AnimatePresence } from 'framer-motion';

// Styles
// ------------
import { Jacket, ProjectType, ProjectTitle, Picture } from './styles';

// Component
// ------------
const SingleCase = ({ details }) => {
    // NOTE • Destructure
    const { title, projectType, featImage, need, solution, value } = details;

    // NOTE • States
    const [caseOpen, setCaseOpen] = useState(false);

    // NOTE • Commands
    const openCase = () => {
        setCaseOpen(true);

        setTimeout(() => {
            Body.lock();
        }, 750);
    }

    const closeCase = () => {
        setCaseOpen(false);
        Body.unlock();
    }

    // NOTE • Alt Backup
    const altText = featImage.alt ? featImage.alt : `Sirio Strategies • Case Study: ${title}`;

    return (
        <>
            <Jacket onClick={openCase}>
                <ProjectType>
                    <span>{projectType.name}</span>
                </ProjectType>

                <ProjectTitle>{title}</ProjectTitle>

                <Picture>
                    <GatsbyImage image={featImage.data} alt={altText} />
                </Picture>
            </Jacket>

            <CasesPortal>
                <AnimatePresence>
                    {caseOpen && (
                        <FullCaseStudy
                            media={featImage.data}
                            mediaAlt={altText}
                            projectTitle={title}
                            companyType={projectType.name}
                            need={need}
                            solution={solution}
                            value={value}
                            onClick={closeCase}
                        />
                    )}
                </AnimatePresence>
            </CasesPortal>
        </>
    );
}

export default React.memo(SingleCase);