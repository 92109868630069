// Imports
// ------------
import React from 'react';
import SingleMember from './SingleMember';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { theme } from '@theme';
import { observer } from 'mobx-react-lite';
import { profileBg } from '@states';

// Styles
// ------------
import { Jacket } from './styles';

// Component
// ------------
gsap.registerPlugin(ScrollTrigger);

const Members = ({ members }) => (
    <>
        <Jacket pad bgActive={profileBg.isActive}>
            {members.map(({ firstName, lastName, role, hasLinkedin, linkedinUrl, profilePhoto, about }) => (
                <SingleMember
                    key={firstName + lastName}
                    firstName={firstName}
                    lastName={lastName}
                    role={role}
                    hasLinkedin={hasLinkedin}
                    linkedinUrl={linkedinUrl}
                    profilePhoto={profilePhoto}
                    about={about}
                />
            ))}
        </Jacket>

        <div id="bg-portal" style={{ background: theme.colors.brand.bc1 }} />
    </>
);

export default observer(Members);