// Imports
// ----------
import React, { useRef, useEffect } from 'react';
import Members from './Members';
import MobileMembers from './MobileMembers';
import { Row, Column } from '@waffl';
import { GatsbyImage } from 'gatsby-plugin-image';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Styles
// ------------
import { Jacket, Background, Content } from './styles';

// Component
// ------------
gsap.registerPlugin(ScrollTrigger);

const Team = ({ heroImage, heroTitle, team }) => {
    // NOTE • Refs
    const trigger = useRef();
    const target = useRef();

    // NOTE • Breakpoints
    const bp = useBreakpoint();

    // NOTE • Onload: scroll Animation
    useEffect(() => {
        const timer = setTimeout(() => {
            gsap.to(target.current, {
                y: `0%`,
                ease: 'none',
                scrollTrigger: {
                    trigger: trigger.current,
                    start: '0% 100%',
                    end: '100% 100%',
                    scrub: 0.5,
                    markers: false,
                },
            });
        }, 250);

        return () => {
            clearTimeout(timer);
        }
    }, []);

    return (
        <>
            <Jacket pad ref={trigger}>
                <Background ref={target}>
                    <GatsbyImage image={heroImage.data} alt={heroImage.alt} />
                </Background>

                <Row isExpanded isMaxHeight>
                    <Column small={12} medium={10} pushMedium={1} large={8} pushLarge={2} mpad isMaxHeight>
                        <Content>{heroTitle}</Content>
                    </Column>
                </Row>
            </Jacket>

            {bp.large ? <Members members={team} /> : <MobileMembers members={team} />}

            <div id="profile-portal" />
        </>
    );
}

export default Team;