// Imports
// ------------
import { Component } from 'react'
import ReactDOM from 'react-dom';

// const portalRoot = document.querySelector("#bg-portal");

export default class ProfilePortal extends Component {

    constructor() {
        super()
        // Use a ternary operator to make sure that the document object is defined
        this.el = typeof document !== `undefined` ? document.createElement('div') : null
    }

    componentDidMount = () => {
        const portalRoot = document.querySelector("#cases-portal");
        portalRoot.appendChild(this.el)
    }

    componentWillUnmount = () => {
        const portalRoot = document.querySelector("#cases-portal");
        portalRoot.removeChild(this.el)
    }

    render() {
        const { children } = this.props

        // Check that this.el is not null before using ReactDOM.createPortal
        if (this.el) {
            return ReactDOM.createPortal(children, this.el)
        } else {
            return null
        }

    }
}