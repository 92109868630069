// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section, Div, Em, Span } from '@tackl';
import { type } from '@tackl-type';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    position: relative;
    z-index: 13;
    background: ${props.theme.colors.brand.bc1};
`);

export const Grad = styled.div(props => css`
    position: absolute;
    top: -200vh; left: 0; right: 0; bottom: 0;
    background: linear-gradient(90deg, ${props.theme.colors.brand.bc2} 0%, ${props.theme.colors.brand.bc3} 100%);
    opacity: 0;
    pointer-events: none;

    ${breakup.large`
    
    `}
`);

export const Content = styled(Div)(props => css`
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: space-between;


    ${breakup.large`
        position: sticky;
        top: ${props.theme.space.large}; left: 0;
        min-height: calc(100vh - (${props.theme.space.large}*2) );
        max-height: 50rem;
    `}
`);

export const HelperText = styled(Span)(props => css`
    display: flex;
    align-items: center;
    gap: 1.2rem;

    svg {
        stroke-width: 2;
        stroke: ${props.theme.colors.brand.bc5};
    }
`);


export const Heading = styled.h3(props => css`
    ${type}

    font-size: 4.8rem;
	line-height: 1.1;
	letter-spacing: -1px;

    ${breakup.medium`
        font-size: 9.6rem;
		letter-spacing: -0.2rem;
    `}
`);


export const Filters = styled.div(props => css`
    position: relative;
    left: -3.6rem;
    width: calc(100% + 7.2rem);
    overflow: scroll;


    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }

    ${breakup.large`
        width: 100%;
        left: 0;
        overflow: hidden;
    `}
`);

export const FilterList = styled.div(props => css`
    display: flex;
    gap: 1.2rem;
    width: 100%;
    overflow: scroll;
    margin: 0 3.6rem;

    ${breakup.large`
        overflow: hidden;
        margin: 0;
    `}
`);

export const FilterLabel = styled(Em)(props => css`
    position: sticky;
    top: 0; left: 0;
    margin-bottom: 2.4rem;
    opacity: 0.6;
    padding: 0 3.6rem;

    ${breakup.large`
        padding: 0;
        position: relative;
    `}
`);


export const Cases = styled(Div)(props => css`
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    position: relative;
    left: -3.6rem;
    width: calc(100% + 7.2rem);

    gap: 1.2rem;
    margin-top: 3.6rem;
    padding: 0 3.6rem;

     -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }

    ${breakup.medium`
        gap: 2.4rem;
        padding: 0 calc(8.333vw + 3.3rem);
        margin-top: 6rem;
    `}

    ${breakup.large`
        margin: 0;
        flex-flow: column;
        gap: 3.6rem;
        width: auto;
        padding: 0;
        left: auto;
    `}
`);