// Imports
// ------------
import React from 'react';
import Icon from '@icons';
import SectionTitle from './SectionTitle';
import { GatsbyImage } from 'gatsby-plugin-image';
import { StructuredText } from 'react-datocms';


// Images
// ------------
import needIcon from '@images/case-icons/icon-need.svg';
import solutionIcon from '@images/case-icons/icon-solution.svg';
import valueIcon from '@images/case-icons/icon-value.svg';

// Styles
// ------------
import { Jacket, Picture, Details, DetailsHeader, DetailsMain, Name, CompanyType, DetailsMainContent, DetailsMainContentSection, Close } from './styles';

// Component
// ------------
const FullCaseStudy = ({ media, mediaAlt, projectTitle, companyType, need, solution, value, onClick }) => {

    // NOTE • Framer motion variables
    const modalFade = {
        initial: {
            opacity: 0,
        },
        animate: {
            opacity: 1,
            transition: { duration: 0.75, delay: 0, ease: [0.8, 0, 0, 1] },
        },
        exit: {
            opacity: 0,
            transition: { duration: 0.5, delay: 0.5, ease: [0.8, 0, 0, 1] },
        }
    };

    const pictureSlide = {
        initial: {
            clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
        },
        animate: {
            clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
            transition: { duration: 1, delay: 0.5, ease: [0.8, 0, 0, 1] },
        },
        exit: {
            clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
            transition: { duration: 1, delay: 0, ease: [0.8, 0, 0, 1] },
        },
    }

    const contentSlide = {
        initial: {
            y: '100%'
        },
        animate: {
            y: '0%',
            transition: { duration: 1, delay: 0.5, ease: [0.8, 0, 0, 1] },
        },
        exit: {
            y: '100%',
            transition: { duration: 1, delay: 0, ease: [0.8, 0, 0, 1] },
        },
    }


    return (
        <Jacket {...modalFade} key="caseModal">
            <Picture {...pictureSlide} key="casePic">
                <GatsbyImage image={media} alt={mediaAlt} />
            </Picture>

            <Details {...contentSlide} key="content">
                <DetailsHeader>
                    <CompanyType>
                        <span>{companyType}</span>
                        <span>Case Study</span>
                    </CompanyType>
                    <Name>{projectTitle}</Name>
                </DetailsHeader>

                <DetailsMain>
                    <DetailsMainContent>
                        <DetailsMainContentSection>
                            <SectionTitle content="The Need" icon={needIcon} />
                            <StructuredText data={need.value} />
                        </DetailsMainContentSection>

                        <DetailsMainContentSection>
                            <SectionTitle content="Solution" icon={solutionIcon} />
                            <StructuredText data={solution.value} />
                        </DetailsMainContentSection>

                        <DetailsMainContentSection>
                            <SectionTitle content="Value Delivered" icon={valueIcon} />
                            <StructuredText data={value.value} />
                        </DetailsMainContentSection>
                    </DetailsMainContent>
                </DetailsMain>
            </Details>

            <Close type="button" onClick={onClick}>
                <Icon type="close" />
            </Close>
        </Jacket>
    );
}

export default React.memo(FullCaseStudy);