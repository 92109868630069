// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Div, ContentBlock } from '@tackl';
import { type, emStyles } from '@tackl-type';
import { motion } from 'framer-motion';

// Exports
// ------------
export const Jacket = styled(motion.section)(props => css`
    position: fixed;
    z-index: 999;
    top: 0; left: 0; right: 0; bottom: 0;
    background: rgba(000,000,000,0.9);
    opacity: 0;
`);

// NOTE • Picture
export const Picture = styled(motion.picture)(props => css`
    position: fixed;
    z-index: 1;
    top: 0; right: 0; bottom: 40%;
    width: 100%;
    overflow: hidden;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);

    ${breakup.large`
        width: 50%;
        bottom: 0;
    `}

    .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
    }
`);

// NOTE Bio
export const Details = styled(motion.div)(props => css`
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 50vh 0 0;
    transform: translateY(0vh);
    overflow: scroll;
    overflow-x: hidden;

    ${breakup.large`
        padding: 6rem 6rem 0;
    `}
`);

export const DetailsHeader = styled(Div)(props => css`
    position: relative;
    top: 0rem;
    left: 0;
    width: 100%;
    padding: 3.6rem;

    display: flex;
    flex-direction: column;
    gap: 3.6rem;
    
    background: linear-gradient(90deg,
        ${props.theme.colors.brand.bc2} 0%,
        ${props.theme.colors.brand.bc3} 100%
    );

    ${breakup.large`
        width: 50vw;
        padding: 8.4rem;
        gap: 6rem;
    `}
`);

export const DetailsMain = styled(Div)(props => css`
    position: relative;
    z-index: 3;
    top: 0;
    right: 0;
    left: 0;
`);

export const DetailsMainContent = styled(Div)(props => css`
    min-height: 100vh;
    width: 100%;
    padding: 3.6rem;
    background: ${props.theme.colors.brand.bc5};

    display: flex;
    flex-direction: column;
    gap: 4.8rem;
    padding: 3.6rem;

    ${breakup.large`
        width: 50vw;
        padding: 8.4rem;
        gap: 8.4rem;
    `}
`);

export const DetailsMainContentSection = styled(ContentBlock)(props => css`


    * {
        color: ${props.theme.colors.brand.bc1trans60} !important;
    }
`);

export const Name = styled.h2(props => css`
    ${type}
    font-size: 3.6rem;
	line-height: 1.2;

    ${breakup.large`
        font-size: 6rem;
		letter-spacing: -0.2rem;
        margin: 1.2rem 0 2.4rem;
    `}
`);

export const CompanyType = styled.div(props => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
        ${emStyles}
        padding: 1.3rem 2.4rem 1.1rem;
        border: 2px solid ${props.theme.colors.brand.bc5};
        
        &:first-child {
            color: ${props.theme.colors.brand.bc2};
            background: ${props.theme.colors.brand.bc5};
            border-radius: 3.6rem 0 0 3.6rem;
        }

        &:last-child {
            color: ${props.theme.colors.brand.bc5};
            border-radius: 0 3.6rem 3.6rem 0;
        }
    }
`);

// NOTE Close Modal
export const Close = styled.button(props => css`
    position: absolute;
    z-index: 4;
    top: 3.6rem;
    right: 3.6rem;

    padding: 0;
    margin: 0;
    background: transparent;
    cursor: pointer;

    ${breakup.large`
        position: fixed;
        top: 6rem;
        right: 6rem;
    `}

    svg {
        width: 3.6rem;
        height: 3.6rem;
        stroke: ${props.theme.colors.brand.bc5};
        stroke-width: 0.3rem;

        ${breakup.large`
            width: 4.8rem;
            height: 4.8rem;
        `}
    }
`);