// Imports
// ------------
import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player/file';
import Button from '@parts/Button';
import { Row, Column } from '@waffl';
import { StructuredText } from 'react-datocms';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Styles
// ------------
import { Jacket, Media, StickyMedia, Content, Title, Text } from './styles';

// Component
// ------------
gsap.registerPlugin(ScrollTrigger);

const SingleDo = ({ alt, title, text, threeD }) => {
    // NOTE • Refs
    const trigger = useRef();
    const videoRef = useRef();

    // NOTE • State
    const [ready, setReady] = useState(false);

    // NOTE • Video Functions (Performance based)
    const play = () => {
        setReady(true);
    }

    const pause = () => {
        setReady(false);
    }

    // NOTE • Onload - Animation
    useEffect(() => {
        const timer = setTimeout(() => {
            gsap.to(videoRef.current, {
                ease: 'none',
                scrollTrigger: {
                    trigger: trigger.current,
                    start: "0% 100%",
                    end: "100% 0%",
                    scrub: 0,
                    onEnter: play,
                    onLeave: pause,
                    onEnterBack: play,
                    onLeaveBack: pause,
                    markers: false,
                }
            });
        }, 250);

        return () => {
            clearTimeout(timer);
        }
    }, []);

    return (
        <Jacket ref={trigger}>
            <Media isAlt={alt}>
                <StickyMedia ref={videoRef}>
                    <ReactPlayer
                        url={threeD}
                        playing={ready}
                        loop
                        muted
                        playsinline
                        width={`100%`}
                        height={`100%`}
                        controls={false}
                    />
                </StickyMedia>
            </Media>

            <Row isExpanded>
                <Column small={12} medium={10} pushMedium={1} large={5} pushLarge={alt ? 6 : 1} mpad>
                    <Content>
                        <Title data-text={title}>{title}</Title>

                        <Text>
                            <StructuredText data={text} />
                        </Text>

                        <Button
                            to="/contact/"
                            label="Chat to a strategist"
                            icon="chat"
                        />
                    </Content>
                </Column>
            </Row>
        </Jacket>
    );
}

export default React.memo(SingleDo);