// Imports
// ------------
import React, { useRef, useEffect, useState } from 'react';
import Icon from '@parts/Icons';
import { Row, Column } from '@waffl';
import ScrollButton from '@parts/ScrollButton';
import ReactPlayer from 'react-player/file';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import vid from '@images/hero-home.mp4';

// Styles
// ------------
import { Jacket, FakeJacket, ScrollButtonPos, Content, Title, SubContent, SubText, Media } from './styles';

// Component
// ------------
gsap.registerPlugin(ScrollTrigger);

const Hero = ({ pageTitle, subtext }) => {
    // NOTE • State
    const [ready, setReady] = useState(false);

    // NOTE • Refs
    const trigger = useRef();
    const videoRef = useRef();
    const target = useRef();

    // NOTE • Functions
    const handleReady = () => {
        setReady(true);
    };

    // NOTE • Video Functions (Performance based)
    const play = () => {
        setReady(true);
    }

    const pause = () => {
        setReady(false);
    }

    // NOTE • Onload - Animation
    useEffect(() => {
        const timer = setTimeout(() => {
            gsap.to(target.current, {
                y: "-30%",
                ease: 'none',
                scrollTrigger: {
                    trigger: trigger.current,
                    start: '100% 100%',
                    end: '200% 100%',
                    scrub: 0.5,
                    markers: false,
                },
            });

            gsap.to(target.current, {
                opacity: 0,
                ease: 'none',
                immediateRender: false,
                scrollTrigger: {
                    trigger: trigger.current,
                    start: '101% 0%',
                    end: '102% 0%',
                    scrub: 0,
                    markers: false,
                },
            });

            gsap.to(videoRef.current, {
                ease: 'none',
                scrollTrigger: {
                    trigger: videoRef.current,
                    start: "100% 0%",
                    end: "100% 0%",
                    scrub: 0,
                    onLeave: pause,
                    onEnterBack: play,
                    markers: false,
                }
            });
        }, 250);

        return () => {
            clearTimeout(timer);
        }
    }, []);

    return (
        <>
            <Jacket ref={target}>
                <Row isExpanded className="row">
                    <Column small={12} large={10} pushLarge={1} mpad className="col">
                        <Content>
                            <Title>{pageTitle}</Title>

                            <SubContent>
                                <Icon type="partnership" />
                                <SubText>{subtext}</SubText>
                            </SubContent>

                            <ScrollButtonPos>
                                <ScrollButton isDown hash="intro" />
                            </ScrollButtonPos>
                        </Content>
                    </Column>
                </Row>

                <Media ref={videoRef}>
                    <ReactPlayer
                        url={vid}
                        onReady={handleReady}
                        playing={ready}
                        muted
                        loop
                        playsinline
                        width={`100%`}
                        height={`100%`}
                        controls={false}
                    />
                </Media>
            </Jacket>

            <FakeJacket ref={trigger} />
        </>
    );
}

export default Hero;