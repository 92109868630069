// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';
import { type } from '@tackl-type';

// Exports
// ------------
export const Jacket = styled.div(props => css`
    display: flex;
    flex-direction: column;

    img {
        display: block;
        width: 6rem;
        height: 6rem;
        margin: 0 0 2.4rem 0;

        ${breakup.large`
            width: 8.4rem;
            height: 8.4rem;
        `}
    }
`);

export const Title = styled.h3(props => css`
    ${type}
    font-size: 3.6rem !important;
	line-height: 1.32;


    display: inline-block;
    background: linear-gradient(90deg,
        ${props.theme.colors.brand.bc2} 0%,
        ${props.theme.colors.brand.bc3} 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;


	${breakup.large`
		font-size: 4.8rem !important;
		letter-spacing: -1px;
	`}
`)