// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';
import { type } from '@tackl-type';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    position: relative;
    z-index: 12;

    ${props.theme.vh}
    background: ${props.theme.colors.brand.bc1};

    ${breakup.large`
        height: 100vh;
    `}

    &:after {
        content: "";
        position: absolute;
        bottom: 0; left: 0; right: 0;
        height: ${props.theme.space.large};

        background: linear-gradient(180deg, ${props.theme.colors.brand.bc1}00 0%, ${props.theme.colors.brand.bc1} 100%);
    }
`);


export const Background = styled.picture(props => css`
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    transform: translateY(-25%);
    background: ${props.theme.colors.global.black};

    .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
        opacity: 0.6;

        ${breakup.large`
            opacity: 1;
        `}
    }
`);


export const Content = styled.h2(props => css`
    ${type}

    position: relative;
    z-index: 11;
    height: 100%;

    font-size: 4.8rem;
	line-height: 1.1;
	letter-spacing: -1px;
    text-align: center;

    flex: 1;
    display: flex;
    align-items: center;

    ${breakup.medium`
        font-size: 9.6rem;
		letter-spacing: -0.2rem;
    `}
`);
