// Imports
// ------------
import React, { useEffect, useRef } from 'react';
import { Row, Column } from '@waffl';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { StaticImage } from "gatsby-plugin-image"

// Styles
// ------------
import { Jacket, Content, FakeJacket, Picture } from './styles';

// Component
// ------------
gsap.registerPlugin(ScrollTrigger);

const Introduction = ({ text }) => {
    // NOTE • Refs
    const trigger = useRef();
    const target = useRef();
    const heart = useRef();

    // NOTE • Onload: scroll Animation
    useEffect(() => {
        const timer = setTimeout(() => {
            gsap.to(target.current, {
                y: "0%",
                ease: 'none',
                immediateRender: true,
                scrollTrigger: {
                    trigger: trigger.current,
                    start: '0% 100%',
                    end: '100% 100%',
                    scrub: 0,
                    markers: false,
                },
            });

            gsap.to(target.current, {
                opacity: 0,
                ease: 'none',
                immediateRender: false,
                scrollTrigger: {
                    trigger: trigger.current,
                    start: '101% 0%',
                    end: '102% 0%',
                    scrub: 0,
                    markers: false,
                },
            });

            gsap.to(heart.current, {
                opacity: 1,
                scale: 1,
                ease: 'none',
                scrollTrigger: {
                    trigger: trigger.current,
                    start: '0% 100%',
                    end: '100% 50%',
                    scrub: 0.5,
                    markers: false,
                },
            });
        }, 250);

        return () => {
            clearTimeout(timer);
        }
    }, []);

    return (
        <>
            <div id="intro" />

            <div>
                <Jacket ref={target}>
                    <Row isExpanded className="row">
                        <Column
                            small={12}
                            medium={10}
                            pushMedium={1}
                            large={8}
                            pushLarge={2}
                            mpad
                            className="col">
                            <Content>{text}</Content>
                        </Column>
                    </Row>

                    <Picture ref={heart}>
                        <StaticImage src="../../../images/heart.png" alt="Background Heart" />
                    </Picture>
                </Jacket>
            </div>

            <FakeJacket ref={trigger} />
        </>
    );
}

export default Introduction;