// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section, Div, H2, ContentBlock } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    position: relative;

    min-height: 100vh;
    width: 100%;
    padding: ${props.theme.space.small} 0;

    ${breakup.large`
        padding: ${props.theme.space.large} 0;
    `}
`);

export const StickyMedia = styled.div(props => css`
    position: relative;

    ${breakup.large`
        position: sticky;
        top: 0; left: 0;
    `}

    &:after {
        content: "";
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
        box-shadow: inset 0 0 9.6rem 8.4rem ${props.theme.colors.brand.bc1};
    }
`);

export const Media = styled(Div)(props => css`
    position: sticky;
    top: 0; left: 0;
    z-index: 1;

    width: calc(100% - 4.8rem);
    max-width: 60rem;
    margin: 0 auto;
    left: 2.4rem;

    ${breakup.large`
        position: absolute;
        top: 0;
        left: ${props.isAlt ? 5 : 50}%;

        width: calc(8.333vw * 5);
        max-width: none;
        height: 100%;
    `}
`);

export const Content = styled(Div)(props => css`
    position: relative;
    z-index: 3;
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 3.6rem;

    text-align: center;

    ${breakup.large`
        gap: 6rem;
        text-align: left;
        align-items: flex-start;
    `};

    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0; left: 0; right: 0; bottom: 0;
        background: linear-gradient(180deg,
            ${props.theme.colors.brand.bc1trans0} 0%,
            ${props.theme.colors.brand.bc1trans80} 12%
        );
    }
`);

export const Title = styled(H2)(props => css`
    position: relative;
    background: linear-gradient(90deg, ${props.theme.colors.brand.bc2} 0%, ${props.theme.colors.brand.bc3} 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    margin: 0 2.4rem;

    ${breakup.large`
        margin: 0;
    `}

    &:after {
        content: attr(data-text);
        position: absolute;
        top: 0; left: 0;
        background: radial-gradient(circle, rgba(255,255,255, 1) 10%, rgba(255,255,255, 0) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        background-size: 200% 100%;
        background-position-x: 0%;
    }
`);

export const Text = styled(ContentBlock)(props => css`
    color: ${props.theme.colors.brand.bc5};
`);