class Body {

    lock = () => {
        const bodyTag = document.querySelector('body');
        const isSSR = typeof window === 'undefined';

        if (!isSSR) {
            this.scrollY = window.scrollY;
            bodyTag.style.overflow = 'hidden';
        }

        function preventScroll(e) {
            // e.preventDefault();
            e.stopPropagation();

            return false;
        }

        bodyTag.addEventListener('wheel', preventScroll, { passive: true });
    }

    unlock = () => {
        const bodyTag = document.querySelector('body');

        bodyTag.style.position = 'initial';
        bodyTag.style.overflow = 'auto';
    }
}

const inst = new Body();
export default inst;