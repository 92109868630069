// Imports
// ------------
import React from 'react';

// Styles
// ------------
import { Jacket, Title } from './styles';

// Component
// ------------
const DetailsTitle = ({ content, icon }) => {
    return (
        <Jacket>
            <img src={icon} alt={content} />
            <Title>{content}</Title>
        </Jacket>
    );
}

export default DetailsTitle;