// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    position: relative;
    z-index: 13;
    background: ${props.theme.colors.brand.bc1};
`);