// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section, Div } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    display: flex;
    flex-flow: column;
    padding-bottom: ${props.theme.space.small};
    width: 100%;

    position: relative;
    z-index: 12;
    background: ${props.theme.colors.brand.bc1};

    ${breakup.large`
        display: block;
        padding-top: ${props.theme.space.large};
        padding-bottom: ${props.theme.space.large};
        padding: 0;
        
    `}
`);