// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Div, Section, H1, Span } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    ${props.theme.vh}
    position: fixed;
    z-index: 10;
    top: 0; left: 0; right: 0;
    background: ${props.theme.colors.brand.bc1};
    min-height: 70rem;
    

    .row,
    .col {
        height: 100%;
    }
`);

export const FakeJacket = styled(Section)(props => css`
    width: 100%;
    ${props.theme.vh}
    min-height: 60rem;
`);

export const Content = styled(Div)(props => css`
    position: relative;
    z-index: 4;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    padding: 10.2rem 0 20.3rem;

    ${breakup.large`
        padding: 13.1rem 0 14.734rem;
    `}
`);

export const Title = styled(H1)(props => css`
    text-align: center;
    padding: 0 1.2rem;


    ${breakup.large`
        text-align: left;
        padding: 0;
    `}
`);

export const SubContent = styled.div(props => css`
    position: absolute;
    bottom: 0; left: 0; right: 0;


    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    gap: 2.4rem;

    padding: 3.6rem 0;

    ${breakup.large`
        flex-flow: row;
        justify-content: flex-start;
        gap: 3.6rem;

        bottom: 8.4rem;
        padding: 0;
    `}

    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 5.4rem;

        width: 48%;
        height: 1px;
        background: ${props.theme.colors.brand.bc5};
        opacity: 0.2;

        ${breakup.large` display: none; `}
    }

    &:before { left: -3.6rem; }
    &:after { right: -3.6rem; }

    svg {
        display: block;
        width: 3.6rem;
        height: 3.6rem;

        fill: none;
        stroke: ${props.theme.colors.brand.bc5};
        stroke-width: 3px;

        ${breakup.large`
            width: 4.8rem;
            height: 4.8rem;
        `}
    }
`);

export const SubText = styled(Span)(props => css`
    opacity: 0.6;
    text-align: center;

    ${breakup.large`
        text-align: left;
        max-width: 69.6rem;
    `}
`);

export const Media = styled(Div)(props => css`
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0; 
    z-index: 1;
    overflow: hidden;

    > div {
        position: relative;
        left: 0%;
        width: 100%;

        ${breakup.large`
            left: 30%;
            width: 70%;
        `}

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 0; left: 0; right: 0; bottom: 0;
        }

        &:before {
            background: ${props.theme.colors.brand.bc2};
            mix-blend-mode: overlay;
        }

        &:after {
            background: linear-gradient(90deg,
                ${props.theme.colors.brand.bc1} 0%,
                ${props.theme.colors.brand.bc1}00 100%);
        }
        
        video {
            object-fit: cover;
        }
    }
`);


export const ScrollButtonPos = styled.div(props => css`
    display: none;
    position: absolute;
    bottom: 8.4rem; right: 0;

    ${breakup.large`
        display: block;
    `}
`)